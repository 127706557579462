import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import React, { Fragment, useEffect, useState } from "react";
import {
  getAllFilters,
  getProjectsCreatedByUser,
  getServicesByCompanyId,
  getServicesByUserId,
} from "../../redux/api";
import { useParams } from "react-router-dom";
import Select from "react-select";
import Datepicker from "react-tailwindcss-datepicker";
import ViewFilterFields from "../../components/FilterFields/ViewFilterFields";

const FilterCardPopup = ({
  open,
  setOpen,
  user,
  initialFilter,
  filter,
  setFilter,
  projectDataFilters,
  setProjectDataFilters,
  filterPreset,
  getProjectsFromDatabase,
}) => {
  const { id } = useParams();
  const scaleClass = open ? "scale-100" : "scale-0";
  const [services, setServices] = useState([]);
  const [userServices, setUserServices] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [selectedWorkType, setSelectedWorkType] = useState(null);
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    if (filter?.service?._id) {
      getAllFilters(filter?.service?._id)
        .then((res) => {
          setFilterData(res.data);
          let projectDataFiltersArray = [];
          if (filterPreset?.filter?.projectDataFilters?.length > 0) {
            projectDataFiltersArray = res.data?.map((filter) => ({
              type: filter?.type,
              subType: filter?.subType,
              name: filter?.name,
              value: filterPreset?.filter?.projectDataFilters?.find(
                (projectDataFilter) => projectDataFilter?.name === filter?.name
              )?.value,
              minValue: filterPreset?.filter?.projectDataFilters?.find(
                (projectDataFilter) => projectDataFilter?.name === filter?.name
              )?.minValue,
              maxValue: filterPreset?.filter?.projectDataFilters?.find(
                (projectDataFilter) => projectDataFilter?.name === filter?.name
              )?.maxValue,
            }));
          } else {
            projectDataFiltersArray = res.data?.map((filter) => ({
              type: filter?.type,
              subType: filter?.subType,
              name: filter?.name,
              value: null,
              minValue: null,
              maxValue: null,
            }));
          }

          setProjectDataFilters(projectDataFiltersArray);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [filter?.service?._id]);

  useEffect(() => {
    if (user?.company?._id) {
      getServicesByCompanyId(user?.company?._id)
        .then((res) => {
          console.log(res);
          setServices(res?.data?.map((userService) => userService?.serviceId));
          setUserServices(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [user?.company?._id]);

  useEffect(() => {
    if (filterPreset && services?.length > 0) {
      console.log("In use effect");
      console.log("Filter preset", filterPreset);
      console.log("Services", services);
      const foundService = services?.find(
        (service) => service?._id === filterPreset?.filter?.service
      );
      const foundWorkType = foundService?.workTypes?.find(
        (workType) => workType?._id === filterPreset?.filter?.workType
      );
      if (foundService) {
        setSelectedService({
          value: foundService?._id,
          label: foundService?.name,
          workTypes: foundService?.workTypes,
        });
      }
      if (foundWorkType) {
        setSelectedWorkType({
          value: foundWorkType?._id,
          label: foundWorkType?.name,
        });
      }
      console.log(filterPreset?.filter);
      setFilter({
        service: foundService,
        workType: foundWorkType?._id,
        date: {
          startDate: filterPreset?.filter?.minDate,
          endDate: filterPreset?.filter?.maxDate,
        },
        minCost: filterPreset?.filter?.minCost,
        maxCost: filterPreset?.filter?.maxCost,
      });

      let projectDataFiltersArray = [];
      if (foundService) {
        getAllFilters(foundService?._id)
          .then((res) => {
            setFilterData(res.data);
            if (filterPreset?.filter?.projectDataFilters?.length > 0) {
              projectDataFiltersArray = res.data?.map((filter) => ({
                type: filter?.type,
                subType: filter?.subType,
                name: filter?.name,
                value: filterPreset?.filter?.projectDataFilters?.find(
                  (projectDataFilter) =>
                    projectDataFilter?.name === filter?.name
                )?.value,
                minValue: filterPreset?.filter?.projectDataFilters?.find(
                  (projectDataFilter) =>
                    projectDataFilter?.name === filter?.name
                )?.minValue,
                maxValue: filterPreset?.filter?.projectDataFilters?.find(
                  (projectDataFilter) =>
                    projectDataFilter?.name === filter?.name
                )?.maxValue,
              }));
            } else {
              projectDataFiltersArray = res.data?.map((filter) => ({
                type: filter?.type,
                subType: filter?.subType,
                name: filter?.name,
                value: null,
                minValue: null,
                maxValue: null,
              }));
            }

            setProjectDataFilters(projectDataFiltersArray);
          })
          .catch((err) => {
            console.log(err);
          });
      }

      console.log("Calling get projects from database");
      getProjectsFromDatabase({
        ...filterPreset?.filter,
        projectDataFilters: projectDataFiltersArray,
      });
      // setProjectDataFilters(filterPreset?.projectDataFilters);
      // getProjectsFromDatabase(filterPreset?.filter);
    }
  }, [filterPreset, services]);

  function filterServiceWorkTypes(service, userServices) {
    // Find the matching service in userServices
    const matchingUserService = userServices?.find(
      (userService) => userService?.serviceId?._id === service?._id
    );

    if (!matchingUserService) {
      // If no matching service is found, return an empty array or the original service workTypes
      return [];
    }

    // Filter the workTypes in the service object
    const filteredWorkTypes = service?.workTypes?.filter((workType) =>
      matchingUserService?.workTypes.some(
        (userWorkType) => userWorkType?._id === workType?._id
      )
    );

    return filteredWorkTypes;
  }
  function resetProjectFields() {
    // logic to reset project fields
    setFilter(initialFilter);
    setSelectedService(null);
    setSelectedWorkType(null);
    setFilterData([]);
    getProjectsFromDatabase();
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="z-50" onClose={setOpen}>
        <Transition.Child
          enter="ease-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100 transform scale-100"
          leaveTo="opacity-0 transform scale-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-500"
              enterFrom="opacity-0 transform scale-0"
              enterTo="opacity-100 transform scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 transform scale-100"
              leaveTo="opacity-0 transform scale-0"
            >
              <Dialog.Panel
                className={`z-50 ${scaleClass} w-80 md:w-96 lg:w-96 fixed mx-auto md:left-4 lg:left-4 lg:top-10 md:top-10 mt-20 lg:my-9 md:my-9 rounded-lg bg-white pt-5 pb-4 text-left shadow-xl transition-all  sm:w-96  h-auto`}
              >
                <div className="absolute top-0 right-0 pt-4 pr-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className=" px-4 pb-4">
                  <div className=" sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Project Details
                    </Dialog.Title>
                  </div>
                </div>
                <div className="p-5">
                  <div className="flex justify-between items-center col-span-12">
                    <h3 className="text-md font-base">Filters</h3>
                    <button
                      className=" bg-gray-100 hover:bg-opacity-90 text-dark text-xs py-1 px-5 rounded"
                      onClick={() => {
                        resetProjectFields();
                      }}
                    >
                      Reset
                    </button>
                  </div>
                  <div className="h-[59vh] overflow-y-auto">
                    <div className="my-3">
                      <Select
                        options={services?.map((service) => ({
                          value: service._id,
                          label: service.name,
                          workTypes: service.workTypes,
                        }))}
                        value={selectedService}
                        onChange={(selectedOption) => {
                          setSelectedService(selectedOption);
                          setSelectedWorkType(null);
                          setFilter({
                            ...filter,
                            service: {
                              _id: selectedOption.value,
                              name: selectedOption.label,
                              workTypes: selectedOption.workTypes,
                            },
                          });
                        }}
                        placeholder="Select Service"
                      />
                    </div>
                    <div className="my-3">
                      {" "}
                      <Select
                        options={filterServiceWorkTypes(
                          filter?.service,
                          userServices
                        )?.map((workType, index) => ({
                          value: workType._id,
                          label: workType.name,
                        }))}
                        value={selectedWorkType}
                        onChange={(selectedOption) => {
                          setSelectedWorkType(selectedOption);
                          setFilter({
                            ...filter,
                            workType: selectedOption?.value,
                          });
                        }}
                        placeholder="Select Project Type"
                      />
                    </div>
                    <div className="my-3 ">
                      <Datepicker
                        placeholder="Date Range"
                        value={filter.date}
                        onChange={(value) => {
                          setFilter({
                            ...filter,
                            date: value,
                          });
                        }}
                      />
                    </div>
                    <div className="my-3">
                      <div className="flex justify-between items-center gap-4">
                        <input
                          type="number"
                          placeholder="Min Cost"
                          className="block w-full rounded-md border-gray-300 pl-10 focus:border-green-600 focus:ring-green-600 sm:text-sm py-2.5 px-3"
                          value={filter.minCost}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              minCost: e.target.value,
                            });
                          }}
                        />
                        <input
                          type="number"
                          placeholder="Max Cost"
                          className="block w-full rounded-md border-gray-300 pl-10 focus:border-green-600 focus:ring-green-600 sm:text-sm py-2.5 px-3"
                          value={filter.maxCost}
                          onChange={(e) => {
                            setFilter({
                              ...filter,
                              maxCost: e.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-span-12">
                      <ViewFilterFields
                        filterData={filterData}
                        filter={filter}
                        setFilter={setFilter}
                        projectDataFilters={projectDataFilters}
                        setProjectDataFilters={setProjectDataFilters}
                        filterPreset={filterPreset}
                      />
                    </div>
                  </div>
                  <div className="mt-3">
                    <button
                      className="w-full bg-green-600 hover:bg-green-700 text-white font-semibold py-2.5 px-3 rounded-md"
                      onClick={() => {
                        getProjectsFromDatabase();
                        setOpen(false);
                      }}
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default FilterCardPopup;
